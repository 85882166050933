<template>
  <div class="w-full relative">
    <input
      type="text"
      :disabled="disabled"
      :placeholder="'Select ' + placeholder"
      @focus.prevent="outerInputOnFocus"
      class="p-1 px-2 appearance-none outline-none w-full text-gray-800"
      :value="value.name"
      id=""
    />
    <div
      @mouseover="mouseOverOptions = true"
      @mouseleave="mouseOverOptions = false"
      ref="optionsDiv"
      id="optionsDiv"
      style="visibility: hidden"
      class="flex z-50 shadow flex-col h-auto justify-start bg-white options absolute top-full right-0 left-0 rounded"
    >
      <input
        ref="searchInput"
        @focusout="onFocusOut"
        :class="{ 'bg-red-300': optionNotInList }"
        :disabled="disabled"
        v-model="searchValue"
        :placeholder="'Search ' + placeholder"
        type="text"
        class="py-3 px-3 shadow w-full"
      />
      <div
        @click="selectOption(option, $event)"
        class="cursor-pointer text-left text-black hover:bg-gray-100 p-3 text-xl"
        v-for="(option, i) in filteredOptions"
        :key="i"
      >
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchDropDown",
  props: ["options", "placeholder", "disabled", "value", "classes"],
  data() {
    return {
      searchValue: "",
      optionsVisible: false,
      mouseOverOptions: false,
    };
  },
  methods: {
    selectOption(option) {
      this.$emit("input", option);
      this.$emit("change", option);
      this.searchValue = "";
      this.$refs.optionsDiv.style.visibility = "hidden";
    },
    outerInputOnFocus(e) {
      this.$refs.optionsDiv.style.visibility = "visible";
      e.preventDefault();
      e.target.blur();
      this.$refs.searchInput.focus();
    },
    onFocusOut(e) {
      if (this.optionNotInList) {
        e.preventDefault();
        return null;
      }
      if (!this.mouseOverOptions) {
        this.$refs.optionsDiv.style.visibility = "hidden";
      }
    },
  },
  computed: {
    filteredOptions() {
      return this.$props.options.filter((option) =>
        option.name.match(new RegExp(this.searchValue, "gi"))
      );
    },
    optionNotInList() {
      if (this.searchValue === "") {
        return false;
      } else {
        const bool = !this.$props.options.some((option) =>
          option.name.match(new RegExp(this.searchValue, "gi"))
        );

        // if (bool) {
        //   this.searchValue = "";
        // }
        return bool;
      }
    },
  },
};
</script>

<style scoped>
#optionsDiv {
  max-height: 400px;
  overflow-y: auto;
}
</style>