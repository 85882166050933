<template>
  <div>
    <HeaderLayout></HeaderLayout>

    <SearchBar></SearchBar>

    <!--Container 3 -->

    <div style="clear: both; display: block; height: 0px"></div>

    <!--Container - Popular Resorts -->
    <div class="w-full mx-auto pt-0 bg-white">
      <div class="max-w-7xl mx-auto px-4 sm:px-6">
        <div class="flex flex-wrap items-center justify-center">
          <div class="border-b pb-4 pt-8 mb-8 w-full">
            <div class="flex flex-col space-y-2">
              <div class="flex space-x-2">
                <h2 class="font-bold text-xl uppercase text-gray-600">
                  All Packages
                </h2>
              </div>
            </div>
          </div>

          <div v-for="p in packages" :key="p._id" class="w-full flex">
            <div
              class="h-48 lg:h-auto lg:w-1/2 flex-none bg-cover bg-center rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
              :style="{
                'background-image': `url(${$customConfig.api_host}/uploads/${p.featuredImage})`,
              }"
              title="Featured"
            ></div>
            <div
              class="border-r border-b border-l border-gray-500 lg:border-l-0 lg:border-t lg:border-grey-800 bg-white rounded-b lg:rounded-b-none lg:rounded-r w-full flex flex-col justify-between leading-normal"
            >
              <div class="mb-8 p-6">
                <p
                  class="uppercase tracking-wide text-sm font-bold text-yellow-400"
                >
                  Book Before {{ p.bookBefore }}
                </p>
                <div class="text-blue-400 font-bold text-2xl mb-2">
                  <router-link :to="'/packages/' + p._id">
                    {{ p.type.name }} at {{ p.property.name }}
                    {{ p.roomType.name }}
                  </router-link>
                </div>
                <p class="text-black text-base">
                  Best Deals & Packages to the Maldives

                  <!-- Stay Between {{ p.stayBetweenStart }} and
                  {{ p.stayBetweenEnd }} -->
                </p>
              </div>
              <div class="px-4 pt-3 pb-4  bg-white">
                <div class="text-lg font-bold text-black tracking-wide">
                  <span class="text-blue-400 text-lg font-bold"> 3 to 7 </span>
                  Nights Package
                </div>
                <div class="flex items-center pt-2">
                  <div class="w-full justify-center">
                    <button
                      class="bg-blue-400 w-full justify-center hover:bg-blue-600 hover:text-white text-white font-bold py-2 px-4 rounded inline-flex items-center"
                    >
                      <router-link :to="'/packages/' + p._id">
                        <span>VIEW DETAILS</span>
                      </router-link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="totalPages > 1" class="my-6 flex justify-center">
      <button
        :class="{
          'bg-primary': canGoPreviousPage,
          'bg-gray-300': !canGoPreviousPage,
          'cursor-not-allowed': !canGoPreviousPage,
        }"
        :disabled="!canGoPreviousPage"
        @click="previousPage"
        class="btn text-white"
      >
        Previous
      </button>
      <span class="mx-3">Page {{ currentPage }} of {{ totalPages }}</span>
      <button
        :class="{
          'bg-primary': canGoNextPage,
          'bg-gray-300': !canGoNextPage,
          'cursor-not-allowed': !canGoNextPage,
        }"
        :disabled="!canGoNextPage"
        @click="nextPage"
        class="btn text-white"
      >
        Next
      </button>
    </div>

    <!--Container 3 -->
    <div class="w-full mx-auto pt-2 bg-white m-20">
      <div class="max-w-6xl mx-auto">
        <div class="flex flex-wrap">
          <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-gray-100">
            <div class="w-full py-6 px-6 text-gray-800">
              <div class="border-b border-gray-800 pb-4 mb-4 w-full">
                <h2 class="font-bold text-xl uppercase text-gray-800">
                  Best Offers And Deals Guarantee
                </h2>
              </div>
              <p>
                Our Best Deals Guarantee assures you receive the best rates
                after you book through us. Dedication to quality, understanding
                the needs of a customer and extensive focus on even the minutest
                of the details have been the biggest strengths for the company’s
                valour.
              </p>
            </div>
          </div>

          <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-gray-200">
            <div class="w-full py-6 px-6 text-gray-800">
              <div class="border-b border-gray-800 pb-4 mb-4 w-full">
                <h2 class="font-bold text-xl uppercase text-gray-800">
                  Your Informations Are Highly Secure
                </h2>
              </div>
              <p>
                Most secured and latest technology available such as SSL and
                CloudFlare Security is protecting all the information that we
                receive on our website.
              </p>
            </div>
          </div>
          <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-gray-100">
            <div class="w-full py-6 px-6 text-gray-800">
              <div class="border-b border-gray-800 pb-4 mb-4 w-full">
                <h2 class="font-bold text-xl uppercase text-gray-800">
                  Highly Experienced Travel agent
                </h2>
              </div>
              <p>
                Travel Connection Maldives pvt ltd was founded in the year 2008,
                TCM, has strived to serve in the best possible fashion to both
                their partners and their customers, alike, since their
                inception.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterLayout> </FooterLayout>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import HeaderLayout from "./PageLayouts/Header.Layout.vue";
import FooterLayout from "./PageLayouts/Footer.Layout.vue";
import SearchBar from "@/components/others/SearchBar";

export default {
  name: "PackagesListPage",
  data() {
    return {
      packages: [],
      totalPages: "",
      currentPage: this.$route.query.page * 1 || 1,
    };
  },
  mounted() {
    this.getPackages();
  },
  methods: {
    async getPackages() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/packages?page=${this.$route.params.currentPage}`
        );

        this.packages = response.data.packages.map((p) => {
          p.deleteState = false;
          return p;
        });
        response.data.package.initial = true;
        response.data.package.bookBefore = moment(
          response.data.package.bookBefore
        ).format("YYYY-MM-DD");
        response.data.package.stayBetweenStart = moment(
          response.data.package.stayBetweenStart
        ).format("YYYY-MM-DD");
        response.data.package.stayBetweenEnd = moment(
          response.data.package.stayBetweenEnd
        ).format("YYYY-MM-DD");
        this.totalPages = response.data.totalPages;
      } catch (e) {
        console.log(e);
      }
    },
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties`
        );
        this.properties = response.data.properties;
        this.totalPages = response.data.totalPages;
        console.log("hello");
      } catch (e) {
        console.log(e);
      }
    },
  },
  nextPage() {
    if (this.canGoNextPage) {
      this.currentPage += 1;
      this.getProperties();
    }
  },
  previousPage() {
    if (this.canGoPreviousPage) {
      this.currentPage -= 1;
      this.getProperties();
    }
  },
  computed: {
    canGoNextPage() {
      return this.currentPage + 1 <= this.totalPages;
    },
    canGoPreviousPage() {
      return this.currentPage - 1 > 0;
    },
  },
  components: {
    HeaderLayout,
    FooterLayout,
    SearchBar,
  },
};
</script>

<style scoped></style>
