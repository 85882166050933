var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('HeaderLayout'),_c('SearchBar'),_c('div',{staticStyle:{"clear":"both","display":"block","height":"0px"}}),_c('div',{staticClass:"w-full mx-auto pt-0 bg-white"},[_c('div',{staticClass:"max-w-7xl mx-auto px-4 sm:px-6"},[_c('div',{staticClass:"flex flex-wrap items-center justify-center"},[_vm._m(0),_vm._l((_vm.packages),function(p){return _c('div',{key:p._id,staticClass:"w-full flex"},[_c('div',{staticClass:"h-48 lg:h-auto lg:w-1/2 flex-none bg-cover bg-center rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden",style:({
              'background-image': `url(${_vm.$customConfig.api_host}/uploads/${p.featuredImage})`,
            }),attrs:{"title":"Featured"}}),_c('div',{staticClass:"border-r border-b border-l border-gray-500 lg:border-l-0 lg:border-t lg:border-grey-800 bg-white rounded-b lg:rounded-b-none lg:rounded-r w-full flex flex-col justify-between leading-normal"},[_c('div',{staticClass:"mb-8 p-6"},[_c('p',{staticClass:"uppercase tracking-wide text-sm font-bold text-yellow-400"},[_vm._v(" Book Before "+_vm._s(p.bookBefore)+" ")]),_c('div',{staticClass:"text-blue-400 font-bold text-2xl mb-2"},[_c('router-link',{attrs:{"to":'/packages/' + p._id}},[_vm._v(" "+_vm._s(p.type.name)+" at "+_vm._s(p.property.name)+" "+_vm._s(p.roomType.name)+" ")])],1),_c('p',{staticClass:"text-black text-base"},[_vm._v(" Best Deals & Packages to the Maldives ")])]),_c('div',{staticClass:"px-4 pt-3 pb-4 bg-white"},[_vm._m(1,true),_c('div',{staticClass:"flex items-center pt-2"},[_c('div',{staticClass:"w-full justify-center"},[_c('button',{staticClass:"bg-blue-400 w-full justify-center hover:bg-blue-600 hover:text-white text-white font-bold py-2 px-4 rounded inline-flex items-center"},[_c('router-link',{attrs:{"to":'/packages/' + p._id}},[_c('span',[_vm._v("VIEW DETAILS")])])],1)])])])])])})],2)])]),(_vm.totalPages > 1)?_c('div',{staticClass:"my-6 flex justify-center"},[_c('button',{staticClass:"btn text-white",class:{
        'bg-primary': _vm.canGoPreviousPage,
        'bg-gray-300': !_vm.canGoPreviousPage,
        'cursor-not-allowed': !_vm.canGoPreviousPage,
      },attrs:{"disabled":!_vm.canGoPreviousPage},on:{"click":_vm.previousPage}},[_vm._v(" Previous ")]),_c('span',{staticClass:"mx-3"},[_vm._v("Page "+_vm._s(_vm.currentPage)+" of "+_vm._s(_vm.totalPages))]),_c('button',{staticClass:"btn text-white",class:{
        'bg-primary': _vm.canGoNextPage,
        'bg-gray-300': !_vm.canGoNextPage,
        'cursor-not-allowed': !_vm.canGoNextPage,
      },attrs:{"disabled":!_vm.canGoNextPage},on:{"click":_vm.nextPage}},[_vm._v(" Next ")])]):_vm._e(),_vm._m(2),_c('FooterLayout')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border-b pb-4 pt-8 mb-8 w-full"},[_c('div',{staticClass:"flex flex-col space-y-2"},[_c('div',{staticClass:"flex space-x-2"},[_c('h2',{staticClass:"font-bold text-xl uppercase text-gray-600"},[_vm._v(" All Packages ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-lg font-bold text-black tracking-wide"},[_c('span',{staticClass:"text-blue-400 text-lg font-bold"},[_vm._v(" 3 to 7 ")]),_vm._v(" Nights Package ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full mx-auto pt-2 bg-white m-20"},[_c('div',{staticClass:"max-w-6xl mx-auto"},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-gray-100"},[_c('div',{staticClass:"w-full py-6 px-6 text-gray-800"},[_c('div',{staticClass:"border-b border-gray-800 pb-4 mb-4 w-full"},[_c('h2',{staticClass:"font-bold text-xl uppercase text-gray-800"},[_vm._v(" Best Offers And Deals Guarantee ")])]),_c('p',[_vm._v(" Our Best Deals Guarantee assures you receive the best rates after you book through us. Dedication to quality, understanding the needs of a customer and extensive focus on even the minutest of the details have been the biggest strengths for the company’s valour. ")])])]),_c('div',{staticClass:"w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-gray-200"},[_c('div',{staticClass:"w-full py-6 px-6 text-gray-800"},[_c('div',{staticClass:"border-b border-gray-800 pb-4 mb-4 w-full"},[_c('h2',{staticClass:"font-bold text-xl uppercase text-gray-800"},[_vm._v(" Your Informations Are Highly Secure ")])]),_c('p',[_vm._v(" Most secured and latest technology available such as SSL and CloudFlare Security is protecting all the information that we receive on our website. ")])])]),_c('div',{staticClass:"w-full sm:w-1/2 md:w-1/3 lg:w-1/3 mb-4 bg-gray-100"},[_c('div',{staticClass:"w-full py-6 px-6 text-gray-800"},[_c('div',{staticClass:"border-b border-gray-800 pb-4 mb-4 w-full"},[_c('h2',{staticClass:"font-bold text-xl uppercase text-gray-800"},[_vm._v(" Highly Experienced Travel agent ")])]),_c('p',[_vm._v(" Travel Connection Maldives pvt ltd was founded in the year 2008, TCM, has strived to serve in the best possible fashion to both their partners and their customers, alike, since their inception. ")])])])])])])
}]

export { render, staticRenderFns }