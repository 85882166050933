<template>
  <!-- SEARCH -->

  <!-- component -->
  <div class="h-auto  w-full bg-gray-900">
    <div
      class="container bg-gray-900 h-auto max-w-7xl mx-auto px-4 sm:px-6 flex justify-center items-center p-2 md:p-0"
    >
      <div class="p-6 grid grid-cols-1 gap-6 bg-gray-900 shadow-lg ">
        <div class="flex flex-row md:flex-row">
          <div class="pt-3 md:pt-0 md:pl-2">
            <select
              v-model="holidayType"
              class="bg-gray-600 text-white p-2 h-10 rounded mr-1"
            >
              <option value="" selected disabled>Holiday Type</option>
              <option value="Family Holiday">Family</option>
              <option value="Honeymoon Couple">Honeymoon</option>
            </select>
          </div>
          <div class="pt-3 md:pt-0 md:pl-6">
            <input
              min="1"
              v-model="numberOfRooms"
              type="number"
              placeholder="No. of Rooms"
              class="bg-gray-600 h-10 text-white p-2 rounded"
            />
          </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="grid grid-cols-2 gap-2 p-2 rounded">
            <div class="flex rounded bg-white items-center p-2">
              <svg
                class="fill-current text-gray-900 mr-2 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
              <search-drop-down
                :options="properties"
                v-model="property"
                :placeholder="'Select Accommodation'"
              />
            </div>
            <div class="flex rounded bg-white items-center p-2">
              <svg
                class="fill-current text-gray-900 mr-2 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
              <search-drop-down
                :options="roomTypes"
                v-model="roomType"
                placeholder="Select Room Type"
                class="bg-white max-w-full focus:outline-none text-gray-900 placeholder-gray-900"
              />
            </div>
          </div>
          <div class="grid grid-cols-3 gap-2 p-2 rounded">
            <div class="flex rounded bg-white items-center p-2">
              <input
                type="text"
                onfocus="(this.type='date')"
                placeholder="Check-in"
                v-model="arrivalDate"
                class="bg-white max-w-full focus:outline-none text-gray-900 placeholder-gray-900"
              />
            </div>
            <div class="flex rounded bg-white items-center p-2">
              <input
                type="text"
                v-model="departureDate"
                onfocus="(this.type='date')"
                placeholder="Check-out"
                class="bg-white max-w-full focus:outline-none text-gray-900 placeholder-gray-900"
              />
            </div>
            <div
              :class="{
                'bg-gray-200': !canProceedToBooking,
                'bg-blue-500': canProceedToBooking
              }"
              class="flex rounded items-center p-2"
            >
              <button
                :disabled="!canProceedToBooking"
                :class="{
                  'bg-gray-200': !canProceedToBooking,
                  'cursor-not-allowed': !canProceedToBooking,
                  'text-gray-400': !canProceedToBooking,
                  'text-white': canProceedToBooking,
                  'bg-blue-500': canProceedToBooking
                }"
                @click.prevent="$router.push('/booking?formstate=yourdetails')"
                class="p-2 w-full h-full rounded-md text-white font-semibold"
              >
                Book
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- SEARCH -->
</template>

<script>
// Added this shit
import SearchDropDown from "@/components/others/SearchDropDown";
import axios from "axios";

export default {
  name: "SearchBar",
  data() {
    return {
      properties: [],
      roomTypes: []
    };
  },
  mounted() {
    this.getProperties();
  },
  watch: {
    property(value) {
      this.getRoomTypes(value._id);
      this.roomType = "";
    }
  },
  methods: {
    async getRoomTypes(id) {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties/${id}/roomTypes`
        );
        this.roomTypes = response.data.roomTypes;
      } catch (e) {
        console.log(e);
      }
    },
    async getProperties() {
      try {
        const response = await axios.get(
          `${this.$customConfig.api_host}/api/v1/properties?getAll=true`
        );
        this.properties = response.data.properties;
      } catch (e) {
        console.log(e);
      }
    }
  },
  computed: {
    canProceedToBooking() {
      if (!this.$store.state.booking.property) {
        return false;
      } else if (!this.$store.state.booking.roomType) {
        return false;
      } else if (!this.$store.state.booking.roomType) {
        return false;
      } else if (!this.$store.state.booking.arrivalDate) {
        return false;
      } else if (!this.$store.state.booking.departureDate) {
        return false;
      } else if (!this.$store.state.booking.holidayType) {
        return false;
      } else if (!this.$store.state.booking.numberOfRooms) {
        return false;
      } else {
        return true;
      }
    },
    property: {
      get() {
        return this.$store.state.booking.property;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", { field: "property", value });
      }
    },
    roomType: {
      get() {
        return this.$store.state.booking.roomType;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", { field: "roomType", value });
      }
    },
    arrivalDate: {
      get() {
        return this.$store.state.booking.arrivalDate;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "arrivalDate",
          value
        });
      }
    },
    departureDate: {
      get() {
        return this.$store.state.booking.departureDate;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "departureDate",
          value
        });
      }
    },
    holidayType: {
      get() {
        return this.$store.state.booking.holidayType;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "holidayType",
          value
        });
      }
    },
    numberOfRooms: {
      get() {
        return this.$store.state.booking.numberOfRooms;
      },
      set(value) {
        this.$store.commit("SET_BOOKING_FIELD", {
          field: "numberOfRooms",
          value
        });
      }
    }
  },
  components: {
    SearchDropDown
  }
};
</script>
